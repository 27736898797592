<template>
  <div class="spotify-downloader">
    <div class="component-header">
      <p class="component-title">
        {{ $t("spotify.download-spotify-to-mp3") }}
      </p>
      <p
        v-show="!vipInfo.is_vip"
        class="upgrade-btn"
        @click="toUpgrade(0)"
        id="upgrade-btn"
      >
        {{ $t("spotify.upgrade") }}
      </p>
    </div>
    <el-input
      v-model="url"
      :class="[
        'input-url',
        status == 'ing' ? 'is-process' : status == 'fail' ? 'error' : '',
        isFocus && 'focus-input',
        isFlash && 'flashing',
        downloadStatus && 'disabled',
      ]"
      placeholder="https://open.spotify.com/track/40Zs9qyx86prd7zU5nYrzx"
      @focus="isFocus = true"
      @blur="isFocus = false"
      @input="handleInputUrl"
      @change="handleInputUrl"
      id="music-link"
    >
      <template slot="append">
        <span></span>
        <template v-if="downloadStatus">
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('spotify.please_wait')"
            placement="top"
          >
            <span @click="handleAnalyse">
              {{
                status == "ing" ? $t("spotify.cancel") : $t("spotify.search")
              }}
            </span>
          </el-tooltip>
        </template>
        <template v-else>
          <span id="dark-blue" @click="handleAnalyse">
            {{ status == "ing" ? $t("spotify.cancel") : $t("spotify.search") }}
          </span>
        </template>
      </template>
    </el-input>
    <p v-show="status == 'fail'" class="error-tip">
      <img src="@/assets/img/icon/close-red.svg" alt="close" />
      {{
        $t("spotify.you_may_give_an_incorrect_url_change_a_url_or_try_again")
      }}
    </p>
    <div
      class="grow-container"
      :style="status == 'success' ? '' : 'margin-top: 40px'"
    >
      <template v-if="status == ''">
        <p class="start">{{ $t("spotify.get_started") }}</p>
        <img
          src="@/assets/img/personal/downloader/get-start.png"
          alt="get-start"
        />
      </template>
      <template v-else-if="status == 'ing'">
        <img
          src="@/assets/img/personal/downloader/processing.png"
          alt="processing"
        />
        <div class="processing"></div>
      </template>
      <img
        v-else-if="status == 'fail'"
        src="@/assets/img/personal/downloader/fail.png"
        alt="fail"
      />
      <div class="result-container" v-else>
        <p :class="['table-statistics', tableData.length > 0 && 'no-empty']">
          <span @click="handleClearAll">
            <i class="el-icon-delete"></i>
            {{ screenWidth > 576 ? $t("spotify.clear_all") : "" }}
          </span>
          <span>
            {{ $t("spotify.total") }}：
            <span>
              {{ tableData.length || "-" }}
            </span>
          </span>
          <span>
            {{ $t("spotify.selected") }}：
            <span>{{ selectedList.length || "-" }}</span>
            <span class="remark">
              {{ $t("spotify.max_count", { pageSize }) }}
            </span>
          </span>
        </p>
        <div class="table-container">
          <el-table
            :class="[remind && 'remind']"
            style="width: 100%"
            :data="currentPageList"
            ref="resultTable"
            @select="handleSelect"
            @select-all="handleSelectAll"
            @load="handleLoad"
            @row-click="handleRowClick"
          >
            <el-table-column v-if="screenWidth > 1200" width="20" />
            <el-table-column type="selection" width="45" />
            <el-table-column
              class-name="title-column"
              :label="$t('spotify.title')"
              min-width="40%"
            >
              <template slot-scope="scope">
                <div class="detail">
                  <img :src="scope.row.image" alt="image" />
                  <div>
                    <span class="black">{{ scope.row.name }} </span>
                    <span>{{ scope.row.artist }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="screenWidth > 768"
              class-name="album"
              property="album"
              :label="$t('spotify.album')"
              min-width="35%"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column :label="$t('spotify.duration')" min-width="15%">
              <template slot-scope="scope">
                {{ formatTime(scope.row.duration) }}
              </template>
            </el-table-column>
            <el-table-column v-if="screenWidth > 1200" width="20" />
          </el-table>
          <div class="download-btn-container">
            <el-tooltip
              v-show="downloadStatus"
              class="item"
              effect="light"
              placement="top"
              :content="$t('spotify.please_wait')"
            >
              <div
                class="download-btn has-song no-allowed"
                :style="{
                  bottom: tableData.length > pageSize ? '150px' : '80px',
                }"
                @click="handleDownloadList"
              >
                <img src="@/assets/img/icon/download.svg" alt="download" />
                <div
                  class="progress"
                  :style="{
                    background: `conic-gradient(#fff 0%, #fff ${downloadTask.progress}%, transparent ${downloadTask.progress}%)`,
                  }"
                >
                </div>
              </div>
            </el-tooltip>
            <div
              v-show="!downloadStatus"
              :class="['download-btn', selectedList.length && 'has-song']"
              :style="{
                bottom: tableData.length > pageSize ? '150px' : '80px',
              }"
              @click="handleDownloadList"
              id="music-download"
            >
              <img src="@/assets/img/icon/download.svg" alt="download" />
            </div>
          </div>
        </div>
        <div
          v-show="needUpgrade"
          :class="['upgrade-container', isSmall && 'small']"
          :style="{
            height: `calc(100% - ${canSelectHeight}px)`,
          }"
        >
          <p> {{ $t("spotify.upgrade_now") }}</p>
          <p class="p-btn" @click="toUpgrade(1)">
            {{ $t("spotify.upgrade") }}
          </p>
        </div>
      </div>
      <el-pagination
        v-if="tableData.length > pageSize"
        ref="pagination"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="tableData.length"
        @current-change="selectedList = []"
      >
      </el-pagination>
    </div>
    <Popup v-if="retryInfo" :isError="true">
      <template v-slot:popup-content>
        <img
          class="close-btn"
          src="@/assets/img/icon/close.png"
          alt="close"
          @click="retryInfo = ''"
        />
        <p class="text">{{ $t("spotify.oops") }} </p>
        <p>
          {{ $t("spotify.unexpected_error") }}
        </p>
        <p class="p-btn usable" id="dark-blue" @click="handleRetry">
          {{ $t("spotify.retry") }}
        </p>
      </template>
    </Popup>
    <el-dialog
      class="refresh-dialog"
      :visible="showRefresh"
      :close-on-click-modal="false"
      @close="needUpgrade = showRefresh = false"
    >
      <p class="bold">{{ $t("spotify.have_you_made_payment") }}</p>
      <p>{{ $t("spotify.click_yes_below") }}</p>

      <div slot="footer">
        <el-button @click="handleRefresh(false)">{{
          $t("spotify.no")
        }}</el-button>
        <el-button
          id="dark-blue"
          :loading="refreshing"
          @click="handleRefresh(true)"
        >
          {{ $t("spotify.yes") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="buying-dialog"
      :close-on-click-modal="false"
      :visible="buying"
      @close="buying = needUpgrade = false"
    >
      <div>
        <p class="title"> {{ $t("spotify.get_premium") }}</p>
        <p
          class="sub-title"
          v-html="
            $t('spotify.you_have_songs_left', {
              num: vipInfo.incre_value_music_download?.incre_value_over,
            })
          "
        >
        </p>
        <div class="introduce-table">
          <div></div>
          <div class="header-cell">{{ $t("spotify.free_trial") }}</div>
          <div class="header-cell">{{ $t("spotify.premium") }}</div>
          <div class="header-cell app-cell">
            <i class="el-icon-star-on"> </i>
            {{ $t("spotify.desktop_app") }}
          </div>

          <div>
            {{ $t("spotify.limited_features") }}
            <br />
            {{ $t("spotify.current_plan") }}
          </div>
          <div class="buy">
            <div v-for="(item, key) in currentSmcData" :key="key">
              <P>
                <span>{{ item.price }}</span>
                /{{ $t(`spotify.${item.unit}`) }}
              </P>
              <P :class="item.class" @click="toSubscribe(item.id, item.type)">
                <i class="el-icon-shopping-cart-2"></i>
                {{ $t(`spotify.${key}`) }}
              </P>
            </div>
          </div>
          <div class="app-cell download">
            <a
              :href="vendorInfo.smc.win_download"
              @click="handleClickLink('TryWin')"
            >
              {{ $t("spotify.try_lt_free") }}
              <img src="@/assets/img/icon/win-black.svg" alt="win" />
            </a>
            <a
              :href="vendorInfo.smc.mac_download"
              @click="handleClickLink('TryMac')"
            >
              {{ $t("spotify.try_lt_free") }}
              <img src="@/assets/img/icon/mac-black.svg" alt="mac" />
            </a>
          </div>

          <div class="row-label">{{ $t("spotify.audio_quality") }}</div>
          <div>128kbps</div>
          <div>256kbps</div>
          <div class="app-cell"> 320kbps </div>

          <div class="row-label">{{ $t("spotify.download_format") }}</div>
          <div>MP3</div>
          <div>MP3</div>
          <div class="app-cell small">MP3/M4A/WAV/FLAC </div>

          <div class="row-label">{{ $t("spotify.download_limit") }}</div>
          <div>{{ $t("spotify.two_songs") }}</div>
          <div>
            {{ $t("spotify.unlimited") }}&nbsp;
            <span class="remark">
              ({{ $t("spotify.max_count", { pageSize }) }})
            </span>
          </div>
          <div class="app-cell">{{ $t("spotify.unlimited") }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Popup from "@/components/popup.vue";
import { mapGetters } from "vuex";
// import encrypt from "@/utils/encrypt-tf-dev";
import encrypt from "@/utils/encrypt-tf-prod";

export default {
  name: "spotifyDownloader",
  components: {
    Popup,
  },
  data() {
    return {
      url: "",
      status: "", //未开始:'',进行中:'ing',成功:'success',失败:'fail'
      tableData: [],
      isFocus: false,
      isFlash: false,
      selectedList: [],
      currentPage: 1,
      pageSize: 15,
      req: new encrypt("https://member.tunefab.com/"),
      // req: new encrypt("https://pre-member.tunefab.com"),
      downloadStatus: false,
      retryInfo: "",
      needUpgrade: false,
      showRefresh: false,
      refreshing: false,
      remind: false,
      vipInfo: { is_vip: 1 },
      buying: false,
      downloadTask: {
        progress: 0,
      },
      analyseTime: {
        start: 0,
        end: 0,
      },
      downloadTime: {
        start: 0,
        end: 0,
      },
      isSmall: false,
    };
  },
  computed: {
    ...mapGetters(["vendorInfo", "screenWidth"]),
    currentPageList() {
      return (
        this.tableData?.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        ) || []
      );
    },
    canSelectCount() {
      return this.vipInfo.incre_value_music_download?.incre_value_over;
    },
    canSelectHeight() {
      return this.currentPage == 1 ? this.canSelectCount * 74 + 98 : 90;
    },
    currentSmcData() {
      return this.$i18n.locale in this.vendorInfo.smc.package
        ? this.vendorInfo.smc.package[this.$i18n.locale]
        : this.vendorInfo.smc.package["en_US"];
    },
  },
  methods: {
    handleAnalyse() {
      this.currentPage = 1;
      if (this.status == "ing") {
        this.req.cancelRequest();
        this.status = "";
        return;
      }
      if (this.downloadStatus) return;
      if (!this.url) {
        this.isFlash = true;
        const timer = setTimeout(() => {
          this.isFlash = false;
          clearTimeout(timer);
        }, 3000);
        return;
      }
      this.tableData = [];
      this.$eventTracking(
        {
          event: "ClickSearch",
          location: "SpotifyWeb",
        },
        [this.url]
      );
      if (
        !this.url.includes("spotify.com") ||
        !(
          this.url.includes("/track/") ||
          this.url.includes("/playlist/") ||
          this.url.includes("/album/")
        )
      ) {
        this.$eventTracking(
          {
            event: "AnalyzeResult",
            result: "Failed",
            location: "SpotifyWeb",
          },
          [
            this.url,
            this.url.includes("/track/") ? "Single" : "Playlist",
            0,
            "链接错误，必须含spotify.com并且包含track/playlist/album",
          ]
        );
        this.status = "fail";
        return;
      }
      this.analyseTime.start = performance.now();
      this.status = "ing";
      this.req
        .analyseAudio(this.url)
        .then(res => {
          this.analyseSearchTask(res?.data?.key);
        })
        .catch(err => {
          if (this.status != "ing") return;
          this.status = "fail";
          this.retryInfo = {
            event: "analyseAudio",
          };
          this.analyseTime.end = performance.now();
          this.$eventTracking(
            {
              event: "AnalyzeResult",
              result: "Failed",
              location: "SpotifyWeb",
            },
            [
              this.url,
              this.url.includes("/track/") ? "Single" : "Playlist",
              this.formatTime(this.analyseTime.end - this.analyseTime.start),
              err.data?.message || err.message || err,
            ]
          );
        });
    },
    analyseSearchTask(key) {
      this.req
        .searchTask(key, "music_analyse")
        .then(res => {
          this.status = "success";
          this.tableData = res.data.data.list;
          this.analyseTime.end = performance.now();
          try {
            this.$eventTracking(
              {
                event: "AnalyzeResult",
                result: res?.data?.status == "200" ? "Success" : "Failed",
                location: "SpotifyWeb",
              },
              [
                this.url,
                this.url.includes("/track/") ? "Single" : "Playlist",
                this.formatTime(this.analyseTime.end - this.analyseTime.start),
                res?.data?.status == "200"
                  ? res.data.data.list.length
                  : res.data?.message,
              ]
            );
            if (
              res?.data?.status == "200" &&
              this.vendorInfo.name === "TuneFab" &&
              process.env.NODE_ENV === "production"
            ) {
              window.dataLayer.push({
                event: "music-analysis", //固定事件名
                music_url: this.url, // 获取实际的歌曲URL
              });
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(err => {
          if (this.status != "ing") return;
          this.retryInfo = {
            event: "searchTask",
            key,
          };
          this.status = "fail";
          this.analyseTime.end = performance.now();
          this.$eventTracking(
            {
              event: "AnalyzeResult",
              result: "Failed",
              location: "SpotifyWeb",
            },
            [
              this.url,
              this.url.includes("/track/") ? "Single" : "Playlist",
              this.formatTime(this.analyseTime.end - this.analyseTime.start),
              err.data?.message || err.message || err,
            ]
          );
        });
    },
    handleSelect(val, item) {
      this.remind = false;
      if (
        this.tableData.indexOf(item) + 1 > this.canSelectCount &&
        !this.vipInfo.is_vip
      ) {
        this.$refs.resultTable.toggleRowSelection(item, false);
        this.showUpgradeMask();
      } else {
        this.selectedList = val;
      }
    },
    handleSelectAll(val) {
      this.remind = false;
      if (!this.vipInfo.is_vip && val.length > this.canSelectCount) {
        this.$refs.resultTable.clearSelection();
        this.selectedList = this.currentPageList.slice(0, this.canSelectCount);
        this.selectedList.forEach(item => {
          this.$refs.resultTable.toggleRowSelection(item, true);
        });
        this.showUpgradeMask();
        return;
      }
      this.selectedList = val;
    },
    showUpgradeMask() {
      this.isSmall =
        this.$refs.resultTable?.$el?.clientHeight - this.canSelectHeight < 200;
      this.needUpgrade = true;
    },
    formatTime(time) {
      let m = Math.floor(time / 1000 / 60);
      let s = Math.floor((time / 1000) % 60);
      return `${m}:${s < 10 ? "0" + s : s}`;
    },
    handleInputUrl() {
      this.isFlash && (this.isFlash = false);
    },
    handleLoad() {
      const newTextNode = document.createTextNode("page");
      document
        .getElementsByClassName("el-pagination__jump")[0]
        .appendChild(newTextNode);
    },
    handleDownloadList() {
      if (this.selectedList.length == 0) {
        this.remind = true;
        const timer = setTimeout(() => {
          this.remind = false;
          clearTimeout(timer);
        }, 3000);
        return;
      }
      if (this.downloadStatus) return;
      this.downloadTime.start = performance.now();
      this.$eventTracking(
        {
          event: "ClickDownload",
          location: "SpotifyWeb",
        },
        [this.selectedList.length, this.currentPage, this.getTotalDuration()]
      );
      this.$set(this.downloadTask, "progress", 0);
      this.downloadStatus = true;
      this.req
        .downloadList(this.selectedList)
        .then(res => {
          const taskName = this.req.createSearchTask(
            res?.data?.key,
            "music_batch_download"
          );
          this.downloadTask.timer = null;
          this.getProcess(taskName);
        })
        .catch(err => {
          this.retryInfo = {
            event: "downloadList",
          };
          this.downloadStatus = false;
          this.$eventTracking(
            {
              event: "DownloadResult",
              result: "Failed",
              location: "SpotifyWeb",
            },
            [
              this.selectedList.length,
              err.data?.message || err.message || err,
              this.getDownloadDuration(),
              this.getTotalDuration(),
            ]
          );
        });
    },
    getProcess(taskName) {
      //time = 10000, max = 60
      this.req
        .executeSearchTask(taskName)
        .then(res => {
          if (!res.data.finish) {
            let completions = 0,
              total = 0;
            if (res.data.progress) {
              completions = res.data.progress.completions;
              total = res.data.progress.total;
            }
            this.setProgress(Math.min(completions / total, 0.95));
            clearTimeout(this.downloadTask.timer);
            this.downloadTask.timer = setTimeout(() => {
              this.getProcess(taskName);
            }, 10000);
          } else {
            if (res.data.status != "200") {
              this.retryInfo = {
                event: "downloadList",
              };
              this.downloadStatus = false;
              return;
            }
            this.setProgress(1, 10, 100);
            const timer1 = setTimeout(() => {
              window.open(res.data.data.download, "_self");
            }, 500);
            const timer2 = setTimeout(() => {
              this.$nextTick(() => {
                this.getRemainCount();
                clearTimeout(timer1);
                clearTimeout(timer2);
                this.downloadStatus = false;
              });
            }, 1000);
            this.$eventTracking(
              {
                event: "DownloadResult",
                result: res?.data?.status == "200" ? "Success" : "Failed",
                location: "SpotifyWeb",
              },
              [
                this.selectedList.length,
                res?.data?.status == "200"
                  ? this.formatSize(res.data?.data?.size)
                  : res.data.message,
                this.getDownloadDuration(),
                this.getTotalDuration(),
              ]
            );
          }
        })
        .catch(err => {
          this.retryInfo = {
            event: "getProcess",
            name,
          };
          this.$set(this.downloadTask, "progress", 0);
          this.downloadStatus = false;
          this.$eventTracking(
            {
              event: "DownloadResult",
              result: "Failed",
              location: "SpotifyWeb",
            },
            [
              this.selectedList.length,
              err.data?.message || err.message || err,
              this.getDownloadDuration(),
              this.getTotalDuration(),
            ]
          );
        });
    },
    setProgress(progress, count = 20, time = 500) {
      const pre = this.downloadTask.progress;
      const now = progress * 100 || 10;
      const interval = (now - pre) / count;
      const timer = setInterval(() => {
        this.$set(
          this.downloadTask,
          "progress",
          this.downloadTask.progress + interval
        );
        if (this.downloadTask.progress >= now) {
          clearInterval(timer);
        }
      }, time);
    },
    async handleRefresh(boolean) {
      this.$eventTracking({
        event: boolean ? "ClickYes" : "ClickNo",
        location: "ComeBackWindows",
      });
      this.needUpgrade = false;
      if (!boolean) return (this.showRefresh = false);
      this.refreshing = true;
      this.getRemainCount(() => {
        this.refreshing = false;
        this.showRefresh = false;
        if (this.vipInfo.is_vip == 0)
          this.retryInfo = {
            event: "refresh",
          };
      });
    },
    handleRetry() {
      const info = this.retryInfo;
      this.retryInfo = "";
      this.$eventTracking({
        event: "ClickRetry",
        location: "DownloadErrorWindow",
      });
      switch (info.event) {
        case "analyseAudio":
          this.handleAnalyse();
          break;
        case "searchTask":
          this.status = "ing";
          this.analyseSearchTask(info.key);
          break;
        case "downloadList":
          this.handleDownloadList();
          break;
        case "refresh":
          this.handleRefresh(true);
          break;
        case "getProcess":
          this.getProcess(info.name);
          break;
      }
    },
    getRemainCount(callback) {
      this.req.remainCount().then(res => {
        this.vipInfo = res.data;
        this.$parent.isRequest = false;
        callback && callback();
      });
    },
    toSubscribe(id, type) {
      window.open(
        `${
          this.vendorInfo.officialLink[this.$i18n.locale]
        }add-to-cart/new?product_id=${id}&source=${this.$i18n.locale.slice(
          0,
          2
        )}&affiliate=default`,
        "_blank"
      );
      this.showRefresh = true;
      this.buying = false;
      this.handleClickLink(type);
    },
    handleRowClick(row) {
      this.$refs.resultTable.toggleRowSelection(row);
      const selectedRows = this.$refs.resultTable.selection;
      this.handleSelect(selectedRows, row);
    },
    getTotalDuration() {
      return this.formatTime(
        this.selectedList.reduce((a, b) => a + b.duration, 0)
      );
    },
    getDownloadDuration() {
      this.downloadTime.end = performance.now();
      return this.formatTime(this.downloadTime.end - this.downloadTime.start);
    },
    handleClearAll() {
      this.tableData = this.selectedList = [];
      this.needUpgrade = false;
      this.$eventTracking({
        event: "ClickClearAll",
        location: "SpotifyWeb",
      });
    },
    toUpgrade(flag) {
      this.buying = true;
      this.$eventTracking({
        event: "ClickUpgrade",
        location: flag ? "SpotifyWebDisabled" : "SpotifyWeb",
      });
    },
    formatSize(bytes) {
      if (bytes === 0) return "0 字节";

      const units = ["字节", "KB", "MB", "GB", "TB"];
      const k = 1024;
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const formattedSize = (bytes / Math.pow(k, i)).toFixed(2);
      return `${formattedSize} ${units[i]}`;
    },
    handleClickLink(type) {
      this.$eventTracking({
        event: `Click${type}`,
        location: "UpgradeWindows",
      });
    },
  },
  created() {
    this.$parent.isRequest = true;
    this.getRemainCount();
  },
  mounted() {
    const auto = sessionStorage.getItem("smcParseUrl");
    if (auto) {
      this.url = auto;
      this.handleAnalyse();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/spotifyDownloader";
</style>
